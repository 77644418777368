<template>
    <div>
        <base-page>Grab Portal</base-page>
    </div>
</template>

<script>
import basePage from './base.vue'

export default {
    name: 'Home',
    components: {
        basePage,
    },
}
</script>

<style lang="scss">

</style>
